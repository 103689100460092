import { urlBase64ToUint8Array } from '../../utils/urlBase64ToUint8Array';

export async function useNotification(
  registration: ServiceWorkerRegistration
) {
  return new Promise<boolean>(async (resolve, reject) => {
    const subscription = await registration?.pushManager
      .subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(
          process.env.NEXT_PUBLIC_VAPID_PUBLIC_KEY as string
        ),
      })
      .then(async (subscription) => {
        
        let response = await fetch("/api/v1/public/notification/subscribe", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(subscription),
        });

        if (response.ok) {
          resolve(true);
        } else {
          reject(false);
        }
      });
  });
}
