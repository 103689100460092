import { AllyContextProfiles, AllyContextProps, ExtendProps } from 'app';
import { createContext } from 'react';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export const AllyContext = createContext({
	enabled: false,
	properties: {
		blindness: {
			enabled: false,
			imageDescriptions: false,
			screenReader: false,
		},
		colorBlindness: {
			enabled: false,
			brightHightContrast: false,
			contrastMode: false,
			darkHighContrast: false,
			hightSaturation: false,
			lowSaturation: false,
			monochrome: false,
		},
		motorSkillsDisorders: {
			enabled: false,
			keyboardNavigation: false,
			smartNavigation: false,
			voiceCommands: false,
		},
		visuallyImpaired: {
			enabled: false,
			brightHightContrast: false,
			contrastMode: false,
			darkHighContrast: false,
			highlightHeaders: false,
			highlightLinks: false,
			hightSaturation: false,
			imageDescriptions: false,
			lowSaturation: false,
			magnifier: false,
			readableFont: false,
			screenReader: false,
			textMagnifier: false,
		},
		epilepsy: {
			enabled: false,
			blinksBlocking: false,
			enable: false,
			lowSaturation: false,
		},
		adhd: { enabled: false, dictionary: false, readFocus: false },
		learning: {
			enabled: false,
			highlightHeaders: false,
			highlightLinks: false,
			readingGuide: false,
		},
		elder: {
			enabled: false,
			dictionary: false,
			mangnifier: false,
			readableMode: false,
			readFocus: false,
			readingGuide: false,
			textMagnifier: false,
			textReader: false,
		},
		dyslexia: { enabled: false, dictionary: false, readingGuide: false },
	},
	pallet: 'common',
} as Partial<AllyContextProps>);

export const useAccessibilityStore = create<AllyContextProps, any>(
	persist(
		(set, get) => ({
			profiles: {
				blindness: {
					enabled: false,
					imageDescriptions: false,
					screenReader: false,
				},
				colorBlindness: {
					enabled: false,
					brightHightContrast: false,
					contrastMode: false,
					darkHighContrast: false,
					hightSaturation: false,
					lowSaturation: false,
					monochrome: false,
				},
				motorSkillsDisorders: {
					enabled: false,
					keyboardNavigation: false,
					smartNavigation: false,
					voiceCommands: false,
				},
				visuallyImpaired: {
					enabled: false,
					brightHightContrast: false,
					contrastMode: false,
					darkHighContrast: false,
					highlightHeaders: false,
					highlightLinks: false,
					hightSaturation: false,
					imageDescriptions: false,
					lowSaturation: false,
					magnifier: false,
					readableFont: false,
					screenReader: false,
					textMagnifier: false,
				},
				epilepsy: {
					enabled: false,
					blinksBlocking: false,
					enable: false,
					lowSaturation: false,
				},
				adhd: { enabled: false, dictionary: false, readFocus: false },
				learning: {
					enabled: false,
					highlightHeaders: false,
					highlightLinks: false,
					readingGuide: false,
				},
				elder: {
					enabled: false,
					dictionary: false,
					mangnifier: false,
					readableMode: false,
					readFocus: false,
					readingGuide: false,
					textMagnifier: false,
					textReader: false,
				},
				dyslexia: { enabled: false, dictionary: false, readingGuide: false },
			},
			pallet: 'common',
			setPallet: (pallet: keyof AllyContextProfiles) =>
				set((state) => {
					return {
						pallet,
					};
				}),
			getPallet: () => {
				const pallet = get().pallet;
				return pallet;
			},
		}),
		{
			name: 'accessibility-store',
		}
	)
);

export default function AccessibilityProvider(
	props?: ExtendProps<{ value?: Partial<AllyContextProps> }>
) {
	const accessibilityStore = useAccessibilityStore();
	const state = props.value ? { ...props.value } : { ...accessibilityStore };

	return (
		<AllyContext.Provider value={{ ...state } as any}>
			{props.children}
		</AllyContext.Provider>
	);
}
