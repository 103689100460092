/**
 * email: oliveiracaue.dev@gmail.com
 * @author Cauê Oliveira
 */

import {
	MutableRefObject,
	RefObject,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';

export interface UseServiceWorkerProps {
	ref?: RefObject<Worker>;
	path: string;
	register?: boolean;
	onMessage?: <T>(ev: MessageEvent<T>) => void;
}

export function useServiceWorker<T>(props: UseServiceWorkerProps) {
	const [worker] = useState<Worker | undefined>(() => {
		if (typeof window !== 'undefined') {
			const worker = new Worker(props.path);
			return worker;
		}
	});
	const [registration, setRegistration] = useState<ServiceWorkerRegistration>();

	const postMessage = useCallback(
		(message: string) => worker?.postMessage(message),
		[worker]
	);

	useEffect(() => {
		if ('serviceWorker' in navigator) {
			navigator.serviceWorker
				.register(props.path)
				.then((registration) => setRegistration(registration))
				.catch((err) => console.log(err));
		}
	}, [registration, props.path, worker]);

	return useMemo(
		() => ({ worker, registration, postMessage }),
		[worker, registration, postMessage]
	);
}
