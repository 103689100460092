import '../styles/styles.css';
import '../styles/global.css';

import { useEffect } from 'react';

import { AppProps } from 'next/app';
import Head from 'next/head';
import { Raleway } from '@next/font/google';

const raleway = Raleway({
	subsets: ['latin'],
	display: 'block',
	weight: ['100', '200', '300', '400', '500', '600', '700', '800', '900'],
	style: 'normal',
});

import {
	useServiceWorker,
	useGeolocation,
	useWorker,
	useNotification,
} from '@od/layers/src/presentation/hooks';
import AccessibilityProvider, {
	useAccessibilityStore,
} from '@/contexts/accessibility';
/*
brand: {
        black: "#1C1C1C",
        blue: "#3BBDAA",
        gray: "#888888",
        green: {
            50: "#DFF7E840",
            light: "#DFF7E8",
            dark: "#7AE2A8",
        },
        pink: {
            dark: "#ED5D5D",
            light: "#F7BAAD",
        },
        yellow: "#FCC05E",
        white: "#FFFFFF"
*/

export default function App({ Component, pageProps, router }: AppProps) {
	useServiceWorker({ path: '/workers/sw-pwa.js' });
	useGeolocation({ watchPosition: false, enableHighAccuracy: false });

	const { registration } = useWorker({
		path: '/workers/sw-push-notification.js',
		register: true,
	});
	useNotification(registration);

	const accessibilityStore = useAccessibilityStore();

	useEffect(() => {
		navigator.sendBeacon(
			process.env.NEXT_PUBLIC_API_DOMAIN + '/analytics',
			JSON.stringify({
				eventType: 'page',
				origin: window.location.origin,
				title: document.title,
			})
		);
	}, [router.pathname]);

	return (
		<>
			<Head>
				<meta name="viewport" content="initial-scale=1, width=device-width" />
				<link rel="icon" href="/assets/images/favicons/favicon.ico" />
			</Head>

			<AccessibilityProvider value={{ ...accessibilityStore }}>
				{/* <CssBaseline /> */}
				<div className={`${raleway.className} text-black`}>
					<Component {...pageProps} />
				</div>
			</AccessibilityProvider>
		</>
	);
}

{
	/* <ThemeProvider theme={theme}> */
}
{
	/* </ThemeProvider> */
}
