import { MutableRefObject, RefObject, useEffect, useState } from 'react';

export interface IUseWorkerProps {
	ref?: RefObject<Worker>;
	path: string;
	register?: boolean;
	isReady?: boolean;
	onMessage?: <T>(ev: MessageEvent<T>) => void;
}

export function useWorker<T>(props: IUseWorkerProps) {
	const [worker] = useState<Worker | undefined>(() => {
		if (typeof window !== 'undefined') {
			return new Worker(props.path);
		}
	});
	const [registration, setRegistration] = useState<ServiceWorkerRegistration>();
	const [isReady, setIsReady] = useState<boolean>();

	const postMessage = (message: string) => worker?.postMessage(message);

	useEffect(() => {
		worker?.postMessage(
			JSON.stringify({
				fullname: 'Cauê Oliveira',
				position: 'Fullstack Developer',
			})
		);

		worker?.addEventListener('oninstall', (ev) => {
			console.log('oninstall', ev);
		});

		worker?.addEventListener('message', (ev) => {
			// console.log(ev);
		});

		if (navigator.serviceWorker) {
			navigator.serviceWorker
				.register(props.path)
				.then((registration) => setRegistration(registration))
				.catch((err) => console.log(err));

			navigator.serviceWorker
				.getRegistration(props.path)
				.then((registration) => {
					if (registration) {
						setIsReady(true);
					}
				});
		}
	}, [registration]);

	return {
		worker,
		registration,
		postMessage,
		isReady,
	};
}
