import { useCallback, useEffect, useMemo, useState } from "react";

interface IUsePermissionReturn {
  status?: PermissionStatus;
  getPermission(): Promise<PermissionStatus>;
}

export const usePermission = (name: PermissionName): IUsePermissionReturn => {
  const [status, setStatus] = useState<PermissionStatus>();

  const getPermission = useCallback(async () => {
    switch (name) {
      case "notifications":
        return new Promise<PermissionStatus>(async (resolve, reject) => {
          let result = await Notification.requestPermission();
          resolve(result as any);
        });
      default:
        return new Promise<PermissionStatus>(async (resolve, reject) => {
          const result = await navigator.permissions.query({
            name,
          });

          resolve(result);
        });
    }
  }, [name]);

  useEffect(() => {
    getPermission().then((status) => setStatus(status));
  }, [getPermission]);

  return useMemo(() => ({ status, getPermission }), [status, getPermission]);
  
};
